import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterBy } from './AssigneesTypeahead';
import { TrxTypeahead, renderMenu } from './index';
import { colors } from '../theme';

const nameKey = item => item.name;

export const alphabeticalOrder = (a, b, labelKey = nameKey) => {
  const aName = labelKey(a).name.toLowerCase();
  const bName = labelKey(b).name.toLowerCase();
  if (aName > bName) return 1;
  else if (aName < bName) return -1;
  return 0;
};

export const Typeahead = ({ selected, updateSelected, itemClassName, children, options, ...props }) => {
  const addOption = ([option]) => {
    updateSelected([...selected, option]);
  };

  const availableOptions = React.useMemo(() => {
    return options.filter(item => !selected.includes(item));
  }, [selected, options]);

  return (
    <>
      <TrxTypeahead
        {...props}
        isKeppClear
        selected={null}
        options={availableOptions}
        filterBy={filterBy}
        addOption={addOption}
        renderMenu={customRenderMenu}
      />
      {children}
    </>
  );
};

const K = () => '';

export const Selected = ({ onChange, selected, itemClassName = K, labelKey = K }) => {
  const noSelected = selected?.size === 0 || selected?.length === 0;

  const removeSelected = item => {
    const newSelected = selected.filter(i => i !== item);
    onChange(newSelected);
  };

  return (
    <SelectionsContent className={cn('mt-3 flex-wrap', { 'd-none': noSelected, 'd-flex': !noSelected })}>
      {selected.map((element, index) => (
        <SelectedElement
          key={`selected-${index}`}
          className={itemClassName(element)}
          selected={element}
          labelKey={labelKey}
          remove={removeSelected}
        />
      ))}
    </SelectionsContent>
  );
};

const customRenderMenu = props => (results, menuProps) => {
  const newMenuProps = { ...menuProps };
  delete newMenuProps.newSelectionPrefix;
  delete newMenuProps.paginationText;
  delete newMenuProps.renderMenuItemChildren;
  return renderMenu(props, newMenuProps, results, menuItemLabel);
};

const menuItemLabel = (option, { labelKey, extra }) => {
  if (option.paginationOption) return <div className="text-secondary text-center">{option.label || 'See more...'}</div>;
  return (
    <div className="d-flex flex-row justify-content-between align-items-center w-100">
      <div className="text-truncate w-75">{`${labelKey(option)}`}</div>
      {extra && <span className="text-secondary font-size-14 mr-5">{extra(option)}</span>}
    </div>
  );
};

const SelectedElement = ({ selected, labelKey, remove, className = '' }) => {
  const onRemoveClick = e => {
    e.preventDefault();
    e.stopPropagation();
    remove(selected);
  };
  return (
    <div className={`item bg-active font-size-16 border-radius-8 mr-1 mt-1 ${className}`}>
      <span className="text-truncate w-100">
        <FontAwesomeIcon icon="ban" className="inactive-icon" />
        {labelKey(selected) || 'No name'}
      </span>
      <button onClick={onRemoveClick} className="btn btn-link p-0 pr-1 ml-1 font-size-12">
        <FontAwesomeIcon icon="xmark" className="ml-1 text-muted cursor-pointer" />
      </button>
    </div>
  );
};

const SelectionsContent = styled.div`
  max-height: 12rem;
  overflow: scroll;

  .item {
    height: 2rem;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    .inactive-icon {
      display: none;
    }

    &.inactive {
      color: ${colors.gray200};
      .inactive-icon {
        display: inline;
        margin-right: 0.5rem;
        color: ${colors.red_30};
      }
    }
  }

  .btn:hover {
    background-color: ${colors.pearl};
    border-radius: 4px;

    .text-muted {
      color: ${colors.blue400} !important;
    }
  }
`;
