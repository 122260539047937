import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { CircleLogo } from '../../Logo';
import { Initials, UserNameLabel } from '../../Tags';
import { mongoToHistory, formatTrx } from '../../utils';
import { GeoLocationButton } from '../../GeoLocation/GeoLocationButton';

export const getName = (item, user) =>
  item.get('createdByAccountId') === user.get('accountId')
    ? item.get('createdByName')
    : item.get('createdByAccountName');

const getInitialsName = (item, user) =>
  item.get('createdByAccountId') === user.get('accountId')
    ? item.get('createdByInitials') || item.get('createdByName')
    : item.get('createdByAccountName');

export const Name = ({ item, user, isInactive, createdByName = false, taskHistory = false }) => {
  const name = createdByName ? item.get('createdByName') : getName(item, user);
  const isSystemEvent = name === 'TradeTrax';
  return (
    <>
      {name && !isSystemEvent ? (
        <div className="d-inline-block mw-100">
          {createdByName ? (
            <ShowUserAndAccountName
              name={name}
              item={item}
              user={user}
              isInactive={isInactive}
              taskHistory={taskHistory}
            />
          ) : (
            <UserNameLabel
              className={cn('text-dark cursor-default', {
                'font-weight-bold': item.get('createdById') === user.get('_id'),
                'not-highlight': item.get('createdByAccountId') !== user.get('accountId'),
              })}
              isInactive={isInactive}
            >
              {name}
            </UserNameLabel>
          )}
        </div>
      ) : (
        <span className="text-dark">TradeTrax</span>
      )}
    </>
  );
};

export const UserName = ({ user, userId, userName, isInactive }) => (
  <UserNameLabel
    className={cn('text-dark highlight', {
      'font-weight-bold': user.get('_id') === userId,
    })}
    isInactive={isInactive}
  >
    {userName}
  </UserNameLabel>
);

export const Item = ({ item, user, children, geoLocationProps = null }) => {
  return (
    <li>
      <div className="d-flex flex-row">
        <Initials name={getInitialsName(item, user)} size={32} className="mr-1" />
        <div className="d-flex flex-column">
          <div className="mt-1 mb-0">{children}</div>
          <div>
            <span className="trx-time">{mongoToHistory(item.get('createdOn'))}</span>
            {geoLocationProps ? <GeoLocationButton {...geoLocationProps} /> : null}
          </div>
        </div>
      </div>
    </li>
  );
};

export const SystemItem = ({ item, children }) => (
  <li>
    <div className="d-flex flex-row">
      <LogoWrapper>
        <CircleLogo size={32} className="mr-1" />
      </LogoWrapper>
      <div className="d-flex flex-column">
        <div className="mt-1 mb-0">{children}</div>
        <span className="trx-time">{mongoToHistory(item.get('createdOn'))}</span>
      </div>
    </div>
  </li>
);

// Not sure why SVG was not respecting width/height
// I had to wrap it on the span.
const LogoWrapper = styled.span`
  width: 2rem;
  height: 2rem;
  margin-right: 0.25rem;
`;

export const STATUS_MAP = {
  'in-progress': 'In Progress',
  completed: 'Completed',
  'not-started': 'Not Started',
  'not-ready-to-start': 'Not Ready to Start',
};

export const status = item => {
  const value = item.getIn(['data', 'status']);
  return `'${STATUS_MAP[value] || value}'`;
};

export const dateField = (item, field) => formatTrx(item.getIn(['data', field]));

export const CreatedBySameAccount = ({ item, user, children }) =>
  user.get('accountId') === item.get('createdByAccountId') ? children : null;

export const isBuilder = account => account.get('type') === 'builder';

const ShowUserAndAccountName = ({ name, item, user, isInactive, taskHistory }) => {
  return (
    <div className="d-flex flex-row align-items-center" style={{ maxWidth: taskHistory ? '360px' : 'auto' }}>
      <div style={{ flex: '0 1 auto', maxWidth: '45%' }}>
        <UserNameLabel
          className={cn('text-dark cursor-default mw-100', {
            'font-weight-bold': item.get('createdById') === user.get('_id'),
            'not-highlight': item.get('createdByAccountId') !== user.get('accountId'),
          })}
          isInactive={isInactive}
        >
          {name}
        </UserNameLabel>
      </div>
      <span className="text-gray-400 font-size-14 mx-1" style={{ flex: '0 1 auto' }}>
        from
      </span>

      <span
        className={cn('text-gray-800 font-size-14 text-truncate', { 'mr-4': !taskHistory })}
        style={{ flex: '1 1 auto' }}
      >
        {item.get('createdByAccountName')}
      </span>
    </div>
  );
};
