import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfiniteLoader, AutoSizer, List } from 'react-virtualized';
import cn from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import { UserNameLabel } from '../Tags';
import { ChipStatus } from '../ChipStatus';
import { colors } from '../theme';
import { STATUS_MAP } from '../ChipStatus';
import { EmptyState, EmptyStateCenterContainer } from '../EmptyState';
import { JobFeedPlaceholder, EMPTY_JOB_FEED, EMPTY_FEED_ELEMENT } from '../LoadingPlaceholder';
import { getAssigneeBadgeClassName } from '../utils';
import { GeoLocationButton } from '../GeoLocation/GeoLocationButton';
import { useGeoLocationProps } from './useLocationProps';
import { MultiFamilyIcon } from '../Popover/MultiFamily.icons.popover';

const MINIMUM_BATCH_SIZE = 25;
const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf('day');
const YESTERDAY = REFERENCE.clone()
  .subtract(1, 'days')
  .startOf('day');

const weekDaysMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const eventsMap = {
  'check-in': 'Check-In',
  'check-out': 'Check-Out',
  'status-changed': 'Status Update',
  'date-changed': 'Schedule Update',
};

const getWeekDay = date => {
  if (moment(date).isSame(TODAY, 'd')) return 'Today';
  if (moment(date).isSame(YESTERDAY, 'd')) return 'Yesterday';
  return weekDaysMap[moment(date).weekday()];
};

const FeedColumn = styled.ul`
  width: 95%;

  @media (min-width: 1200px) {
    width: 50rem;
  }
`;

export const JobFeed = ({
  jobFeed,
  loadMoreRows,
  loaderRef,
  jobFeedCount,
  linkToTask,
  appContext,
  account,
  user,
  emptyStateText,
}) => {
  const isEmpty = jobFeedCount.size === 0;
  const isLoading = jobFeed === EMPTY_JOB_FEED;
  const windowWidth = window.innerWidth;

  if (isEmpty)
    return (
      <EmptyStateCenterContainer top="0px">
        <EmptyState icon="house" title="No Job Activity" body={emptyStateText} />
      </EmptyStateCenterContainer>
    );
  const rowRenderer = ({ key, index, style }) => {
    const isElementLoading = jobFeed.get(index) === EMPTY_FEED_ELEMENT || !jobFeed.get(index);
    if (isElementLoading)
      return (
        <div key={key} style={style}>
          <FeedColumn className="list-unstyled ml-3 pl-3 mt-3">
            <JobFeedPlaceholder />
          </FeedColumn>
        </div>
      );
    return (
      <div key={key} style={style} className={cn({ 'mt-4': index > 0 })}>
        <FeedColumn className="list-unstyled ml-3 pl-3 mt-3">
          <Group
            actions={jobFeed.getIn([index, 'actions'])}
            date={jobFeed.getIn([index, 'date'])}
            linkToTask={linkToTask}
            appContext={appContext}
            account={account}
            user={user}
          />
        </FeedColumn>
      </div>
    );
  };
  const calculateRowHeight = ({ index }) => {
    // This values are on sync with the styles set on FeedGroup styled component
    const titleHeight = 29.5;
    const topBase = index === 0 ? 1 : 2;
    const topPadding = 24 * topBase;
    const rowHeightBase = windowWidth < 750 ? 101.59 : 88;
    const rowHeight = rowHeightBase * jobFeedCount.getIn([index, 'count']);
    return rowHeight + topPadding + titleHeight;
  };

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !!jobFeed.get(index) && !isLoading}
      loadMoreRows={loadMoreRows}
      rowCount={jobFeedCount.size}
      ref={loaderRef}
      minimumBatchSize={MINIMUM_BATCH_SIZE}
      threshold={1}
    >
      {({ onRowsRendered, registerChild }) => (
        <div style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }} className={cn({ 'd-none': isEmpty })}>
          <AutoSizer>
            {({ width, height }) => {
              return (
                <List
                  ref={registerChild}
                  className="pb-3"
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowCount={jobFeedCount.size}
                  rowHeight={calculateRowHeight}
                  rowRenderer={rowRenderer}
                  width={width}
                />
              );
            }}
          </AutoSizer>
        </div>
      )}
    </InfiniteLoader>
  );
};

JobFeed.defaultProps = {
  linkToTask: ({ taskId }) => `/taska/${taskId}`,
  linkToSub: id => `/sub/${id}`,
};

export const Group = ({ actions, date, linkToTask, account, user, appContext }) => (
  <li className="d-flex flex-column">
    <div className="mb-2">
      {date && <span className="h4 font-weight-bold mr-2">{getWeekDay(date)}</span>}
      {date && <span className="font-weight-bold">{moment(date, 'YYYY-MM-DD').format('MMMM D')}</span>}
    </div>
    <FeedGroup>
      {actions &&
        actions.map((item, index) => (
          <Card
            key={index}
            item={item}
            appContext={appContext}
            account={account}
            user={user}
            linkToTask={linkToTask({ taskId: item.getIn(['task', '_id']) }, false)}
          />
        ))}
    </FeedGroup>
  </li>
);

export const FeedGroup = styled.ul`
  background: ${colors.pearl};
  border-radius: 1rem;
  margin-bottom: 1.25rem;
  padding: 0;
  list-style: none;

  li {
    border-top: solid 1px ${colors.gray};
    padding: 0.85rem 1.875rem 0 1rem;
    min-height: 5.5rem;

    &:first-child {
      border-top: none;
      padding-top: 1.25rem;
    }

    @media (max-width: 750px) {
      height: 6.3rem;
    }

    .icon {
      color: ${colors.gray400};
      padding-right: 8px;
      width: 34px;
      text-align: right;
    }

    .text-gray {
      color: #8a8a8a;
    }
  }
`;

const getActionIcon = action => {
  switch (action) {
    case 'status-changed':
      return 'circle-check';
    case 'date-changed':
      return 'calendar-days';
    default:
      return 'right-to-bracket';
  }
};

export const Card = ({ className = '', item, linkToTask, account, user, appContext }) => {
  const impact = parseInt(item.get('jobDelay') || 0, 10);
  const updatedAt = moment(item.get('updatedAt'));
  const userId = user.get('_id');
  const assigneeAccountId = item.getIn(['assigneeAccount', 'companyId']);
  const accountId = account.get('_id');
  const isAccountAssigned = accountId === assigneeAccountId;
  const assigneeId = item.get('createdByUserId');
  const isInactive = isAccountAssigned && item.get('createdByUserStatus') === 'inactive';
  const status = !!item.get('checkedIn') ? 'checked-in' : item.get('status');
  const geoLocationProps = useGeoLocationProps(item, appContext);

  return (
    <li className={`d-flex flex-row ${className}`}>
      <span className="icon">
        <FontAwesomeIcon icon={getActionIcon(item.get('action'))} className="font-size-12" />
      </span>
      <div className="d-flex flex-column mr-auto mb-2">
        <div className="d-flex align-items-center">
          {linkToTask ? (
            <Link to={{ pathname: linkToTask, state: { referrer: 'feed' } }} className="trx-link text-capitalize ">
              <TaskName className="text-truncate">{item.getIn(['task', 'name'])}</TaskName>
            </Link>
          ) : (
            <TaskName className="text-capitalize font-weight-bold text-truncate">
              {item.getIn(['task', 'name'])}
            </TaskName>
          )}
          <MultiFamilyIcon task={item.get('task')} className="mb-2 ml-2" />
        </div>
        <InfoContainer>
          <span className="text-gray">
            <span className="text-capitalize">{eventsMap[item.get('action')]}</span>
            {` by `}
          </span>
          {isInactive ? (
            <>
              <div className="d-inline-block">
                {isInactive && <FontAwesomeIcon icon="ban" className="mr-1 text-danger muted" />}
                <span
                  className={cn(' font-size-14 w-100 text-truncate text-gray-200', {
                    'ml-1  text-muted': !isInactive,
                  })}
                >
                  <InactiveNameContainer className="text-truncate">
                    {isAccountAssigned && item.get('createdByUserName')}
                    {!isAccountAssigned && item.getIn(['assigneeAccount', 'companyName'])}
                  </InactiveNameContainer>
                </span>
              </div>
            </>
          ) : (
            <UserNameLabel
              className={`not-permission not-badge ${getAssigneeBadgeClassName(
                assigneeId,
                accountId,
                userId,
                assigneeAccountId
              )}`}
            >
              {isAccountAssigned && item.get('createdByUserName')}
              {!isAccountAssigned && item.getIn(['assigneeAccount', 'companyName'])}
            </UserNameLabel>
          )}
          <span className="text-gray">{` at ${updatedAt.format('h:mm A')}`}</span>
          {geoLocationProps ? <GeoLocationButton {...geoLocationProps} /> : null}
        </InfoContainer>
      </div>
      <div className="d-flex flex-column align-items-end mt-n1">
        <ChipStatus status={status} className="mb-1" {...STATUS_MAP[status]} />{' '}
        <CycleTimeContainer
          className={cn({
            'text-danger': impact > 0,
            'text-gray': impact === 0,
            'text-success': impact < 0,
          })}
        >
          {impact > 0 && `${Math.abs(impact)} day cycle time increase`}
          {impact === 0 && 'No cycle time impact'}
          {impact < 0 && `${Math.abs(impact)} day cycle time reduction`}
        </CycleTimeContainer>
      </div>
    </li>
  );
};

const TaskName = styled.span`
  display: inline-block;
  max-width: 250px;
  @media (min-width: 992px) {
    max-width: 450px;
  }
`;

const InactiveNameContainer = styled.span`
  display: inline-block;
  max-width: 120px;
  vertical-align: bottom;
  @media (min-width: 1000px) {
    max-width: 180px;
  }
`;

const InfoContainer = styled.small`
  margin-right: 10px;
`;

const CycleTimeContainer = styled.small`
  white-space: nowrap;
`;
