import { fromJS, List } from 'immutable';
import { markAsSideEffect, markAsSync } from '../../../useController';
import { LIST_VIEW, SHARE_VIEW, EDIT_VIEW } from './ShareFilterSetContext';

markAsSync(setShareView);
export function setShareView(state, filterSet) {
  return state.set('toShare', filterSet).set('filterView', SHARE_VIEW);
}

markAsSync(setListView);
export function setListView(state) {
  return state
    .set('toShare', null)
    .set('filterView', LIST_VIEW)
    .set('selected', List())
    .set('lastSharedIndex', null)
    .set('sharedWith', List())
    .set('shareOptions', List());
}

markAsSync(setEditView);
export function setEditView(state, filterSet, backButton = LIST_VIEW) {
  return state
    .set('filterView', EDIT_VIEW)
    .set('toShare', filterSet)
    .set('backButton', backButton)
    .set('shareOptions', List());
}

markAsSync(goBack);
export function goBack(state, view = LIST_VIEW) {
  let newState = state;
  if (state.get('backButton') === LIST_VIEW) newState = state.set('toShare', null);
  return newState.set('filterView', view).set('shareOptions', List());
}

markAsSync(updateSelectedToShare);
export function updateSelectedToShare(state, selected) {
  return state.set('selected', List(selected));
}

markAsSync(shareFilter);
export function shareFilter(state, users, filter) {
  const index = this.state.get('filterSets').indexOf(filter);
  const isShared = state.getIn(['filterSets', index, 'isShared']);
  const body = {
    ids: users.map(user => user.get('_id')),
  };

  this.filtersService
    .shareFilter(body, { params: { filterId: filter.get('_id') } })
    .then(() => {
      this.updateFilterSharedState(index, true);
      this.addAlert('Filter Set successfully shared.');
    })
    .catch(() => {
      this.addAlert('There was a problem sharing this Filter Set. Please try again.', 'danger');
      this.controller.dispatch([
        state => state.set('lastSharedIndex', null).setIn(['filterSets', index, 'isShared'], isShared),
      ]);
    });

  return state
    .set('selected', List())
    .set('shareOptions', List())
    .set('filterView', LIST_VIEW)
    .set('lastSharedIndex', index)
    .set('toShare', null)
    .setIn(['filterSets', index, 'isShared'], true);
}

markAsSideEffect(saveEditAccess);
export function saveEditAccess() {
  const filterId = this.state.getIn(['toShare', '_id']);
  const index = this.state.get('filterSets').findIndex(item => item.get('_id') === filterId);
  const ids = this.state
    .get('sharedWith')
    .reduce((memo, item) => (item.get('toBeRemoved') ? [...memo, item.get('_id')] : memo), []);

  this.filtersService
    .unshareFilter({ ids }, { params: { filterId } })
    .then(() => this.addAlert('Access to Filter Set successfully revoked.', 'success'))
    .then(() => this.filtersService.readFilterUsersAndRoles({}, { params: { filterId }, query: { shared: true } }))
    .then(({ data }) => {
      const isShared = !!data.length;
      this.updateFilterSharedState(index, isShared);
      this.controller.dispatch([
        state =>
          state
            .setIn(['filterSets', index, 'isShared'], isShared)
            .set('lastSharedIndex', index)
            .set('filterView', LIST_VIEW)
            .set('toShare', null)
            .set('sharedWith', List())
            .set('shareOptions', List()),
      ]);
    })
    .catch(() => {
      this.addAlert('There was a problem revoking access to this Filter Set. Please try again.', 'danger');
    });
}

markAsSync(reorderFilterSets);
export function reorderFilterSets(state, startIndex, endIndex) {
  const filterSets = state.get('filterSets');
  const result = Array.from(filterSets);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return state.set('filterSets', result).update('lastSharedIndex', index => (index === startIndex ? endIndex : index));
}

markAsSync(updateFilterName);
export function updateFilterName(state, filter, value) {
  const index = state.get('filterSets').indexOf(filter);
  return state.setIn(['filterSets', index, 'name'], value);
}

markAsSync(removeFilter);
export function removeFilter(state, filter) {
  const index = state.get('filterSets').indexOf(filter);
  return state.setIn(['filterSets', index, 'toBeRemoved'], true);
}

markAsSideEffect(readFilterSharedWith);
export function readFilterSharedWith(filterSet) {
  const filterId = filterSet.get('_id');
  this.filtersService
    .readFilterUsersAndRoles({}, { params: { filterId }, query: { shared: 'strictTrue' } })
    .then(fromJS)
    .then(response => {
      this.controller.dispatch([state => state.set('sharedWith', response.get('data'))]);
    })
    .catch(error => {
      this.addAlert('There was an error loading the list of users. Please try again.', 'danger');
      throw error;
    });
}

markAsSideEffect(readFilterShareOptions);
export function readFilterShareOptions(filterSet) {
  const filterId = filterSet.get('_id');
  this.filtersService
    .readFilterUsersAndRoles({}, { params: { filterId }, query: { shared: 'strictFalse' } })
    .then(fromJS)
    .then(response => {
      this.controller.dispatch([state => state.set('shareOptions', response.get('data'))]);
    })
    .catch(error => {
      this.addAlert('There was an error loading the list of users. Please try again.', 'danger');
      throw error;
    });
}

markAsSync(removeSharedWith);
export function removeSharedWith(state, item) {
  const index = state.get('sharedWith').indexOf(item);
  return state.setIn(['sharedWith', index, 'toBeRemoved'], true).set('userToDelete', item);
}

markAsSideEffect(updateFilterIsShared);
export function updateFilterIsShared(filterSet, isShared) {
  this.updateFilterSharedState(filterSet, isShared);
}
