import React from 'react';
import cn from 'classnames';
import { SystemNotification } from '../SystemNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserRequestedBadgeClassName } from '../../utils';
import { useAppContext } from '../../globalAppContext';

const reg = /^(?:The\screated\suser\s)(.*)((?:\s(?:has\ssuccessfully).*))$/;
const parseNotification = message => {
  const result = (message || '').match(reg);
  return result || [message, message];
};

export default ({ notification, controller }) => {
  const [, userName, prefix, sufix] = parseNotification(notification.get('message'));
  const isUserInactive = notification.getIn(['data', 'userStatus']) === 'inactive';
  const userInitials = notification.getIn(['data', 'userInitials']);
  const appContext = useAppContext();
  const { user } = appContext.appState.toObject();

  const userClassName = getUserRequestedBadgeClassName(
    notification.getIn(['requestedBy', 'accountId']),
    user.get('accountId')
  );

  return (
    <SystemNotification
      key={notification.get('_id')}
      linkTo={`/users/profile/${notification.getIn(['data', 'userId'])}`}
      time={notification.get('createdAt')}
      redDot={!notification.get('read')}
      markAsSeen={() => controller.markAsSeen(notification)}
      showInitial={userInitials}
    >
      {isUserInactive && <FontAwesomeIcon icon="ban" className="mr-1 text-danger muted" />}
      {userName && (
        <span
          className={cn('text-truncate', userClassName, {
            'text-gray-200': isUserInactive,
          })}
        >
          {userName}
        </span>
      )}
      {prefix}
      {sufix}
    </SystemNotification>
  );
};
