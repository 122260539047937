import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTimeAgo } from '../useTimeAgo';
import { mongoToHistory } from '../utils';
import { Name } from '../TaskHistory/Events/common';
import { colors } from '../theme';
import { getUserDomain } from '../utils';
import { CommentWithTags } from './CommentWithTags';

export const Note = ({ note, user, remove, isActive, companyId }) => {
  const { createdById, createdByAccountId, htmlText, taggedParties, text } = note.toObject();
  const isUserInactive =
    note.get('createdByAccountId') === user.get('accountId') ? note.get('createdByStatus') === 'inactive' : false;
  const showDeleteOption = React.useMemo(() => {
    const { isBuilder } = getUserDomain(user);
    const sameCompany = companyId === createdByAccountId;
    const sameUser = createdById === user.get('_id') && sameCompany;
    const sameAccount = user.get('accountId') === createdByAccountId && sameCompany;

    return isBuilder || sameUser || sameAccount;
  }, [user]);

  return (
    <li>
      <RemoveButton
        onClick={remove}
        isActive={isActive}
        className={cn('btn btn-link', { 'd-none': !showDeleteOption })}
      >
        <FontAwesomeIcon icon="trash" />
      </RemoveButton>
      <Name user={user} item={note} isInactive={isUserInactive} createdByName />
      <span className="text-gray-400 font-size-12 d-block mt-1 mb-2">
        {useTimeAgo(note.get('createdOn'), mongoToHistory)}
      </span>
      <CommentWithTags text={htmlText || text} user={user} taggedParties={taggedParties?.toJS()} />
    </li>
  );
};

const RemoveButton = styled.button`
  ${props => `
  position: absolute;
  top: 0;
  right: 0;
  color: ${props.isActive ? colors.blue300 : colors.gray400};
`}
`;
