import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SHARE_VIEW } from './ShareFilterSetContext';
import * as OutSelections from '../../../Typeahead/OutSelectionsTypeahead';
import { PrimaryButton } from '../../../Button';
import { plural } from '../../../utils';

const getUsersType = users => {
  let customUsers = 0;
  let regularUsers = 0;
  users.map(user => {
    if (user.get('type') === 'user') regularUsers++;
    else customUsers++;
  });
  return { customUsers, regularUsers };
};

export const FilterShareView = ({ state, controller }) => {
  const { toShare: filterSet, shareOptions, selected } = state.toObject();
  const onClick = () => controller.shareFilter(selected, filterSet);
  const isShared = filterSet.get('isShared');
  const showShareSection = isShared && !selected.size;
  const sharedSize = state.get('sharedWith').size;

  const { customUsers, regularUsers } = getUsersType(state.get('sharedWith'));
  const regularUsersLabel = regularUsers > 0 ? `${plural(regularUsers, 'User')}` : '';
  const customUsersLabel = customUsers > 0 ? `${customUsers} ${plural(customUsers, 'Custom Role')}` : '';
  const andLabel = customUsersLabel && regularUsersLabel ? ' and ' : '';
  const shareWithLabel = `Shared with ${
    regularUsers > 0 ? `${regularUsers} ${regularUsersLabel}` : ''
  }${andLabel}${customUsersLabel}`;

  React.useEffect(() => {
    if (isShared) controller.readFilterSharedWith(filterSet);
    controller.readFilterShareOptions(filterSet);
  }, [controller, isShared, filterSet]);

  const options = React.useMemo(() => {
    return shareOptions.filter(option => option.get('status') !== 'inactive').toArray();
  }, [shareOptions]);

  if (isShared && sharedSize === 0) return null;

  return (
    <>
      <Modal.Header closeButton>
        <div className="d-flex flex-column align-items-start ">
          <button
            onClick={controller.setListView}
            className="btn text-muted position-relative"
            style={{ bottom: '0.5rem', right: '0.5rem' }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </button>
          <Modal.Title className="mt-3">{`Share Filter Set: ${filterSet.get('name')}`}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {`You can share this Filter Set with specific users and/or Custom Roles. Users you share this Filter Set with will
        have access but will not be able to rename or remove it.`}
        <div className="mt-4">
          {!!shareOptions.size ? (
            <OutSelections.Typeahead
              id="share-filter-typeahead"
              size="lg"
              labelKey={option => option.get('name')}
              extra={extraMenuItem}
              placeholder="Choose a User and/or Custom Role"
              filterSelected={(option, selected) => selected.get('_id') !== option.get('_id')}
              options={options}
              selected={selected}
              updateSelected={selected => controller.updateSelectedToShare(selected)}
              itemClassName={option => option.status}
              clearButton
            >
              <OutSelections.Selected
                selected={selected}
                onChange={selected => controller.updateSelectedToShare(selected)}
                labelKey={option => option.get('name')}
              />
            </OutSelections.Typeahead>
          ) : (
            <p className="mt-2 font-size-14 text-gray-400">No results found</p>
          )}
        </div>
      </Modal.Body>
      {showShareSection && (
        <div className="d-flex justify-content-between align-items-center mx-5">
          <span className="text-gray-400">{shareWithLabel}</span>
          <button onClick={() => controller.setEditView(filterSet, SHARE_VIEW)} className="btn btn-link">
            Edit access
          </button>
        </div>
      )}
      <Modal.Footer>{!!selected.size && <PrimaryButton onClick={onClick}>Share</PrimaryButton>}</Modal.Footer>
    </>
  );
};

const extraMenuItem = option => {
  if (option.get('type') === 'role') return 'Custom Role';
  return null;
};
