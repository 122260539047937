import moment from 'moment';
import { Map, fromJS } from 'immutable';
import { css } from 'styled-components';
import { colors } from './theme';
import { throttle } from 'lodash';

const ISO = 'YYYY-MM-DD';
const TRX = 'MM/DD/YY';
const TRX_FULL = 'MM/DD/YYYY';
const TRX_TEXT = 'MMM DD';
const TRX_TEXT_YEAR = 'MMM DD, YYYY';
const HISTORY_DATE = 'MMMM D [at] h:mma';
const HISTORY_DATE_YEAR = 'MMMM D, YYYY [at] h:mma';

export const ONE_MINUTE = 60000;
export const ONE_HOUR = 3600000;
export const ONE_DAY = 86400000;
export const ONE_WEEK = ONE_DAY * 7;

export const K = k => k;

export const OVERDUE_FINISH = 'finish';
export const OVERDUE_START = 'start';

export const isoToTrx = value => moment(value, ISO).format(TRX);
export const isoToTrxFull = value => moment(value, ISO).format(TRX_FULL);
export const trxToISO = value => moment(value, TRX).format(ISO);
export const trxFullToISO = value => moment(value, TRX_FULL).format(ISO);
export const isValidTrx = value => moment(value, TRX).isValid();
export const formatISO = date => moment.parseZone(date).format(ISO);
export const formatTrx = date => moment.parseZone(date).format(TRX_FULL);
export const mongoToMoment = date => moment.parseZone(date);
export const mongoToTrx = date => (date ? moment.parseZone(date).format(TRX_FULL) : '');
export const mongoToTemplateFormat = date => (date ? moment(date).format(TRX_FULL) : '');
export const mongoToText = date => moment.parseZone(date).format(TRX_TEXT);
export const mongoToISO = date => moment.parseZone(date).format(ISO);
export const datepickerFormat = (date, format = TRX_FULL) => (date ? moment(date, format).toDate() : null);
export const datepickerFormatIncremented = (date, daysAdded) =>
  date
    ? moment(date, TRX_FULL)
        .add(daysAdded, 'd')
        .toDate()
    : null;

export const mongoToHistory = date => {
  const historyDate = moment(date);
  const thisYear = new Date().getFullYear();
  return historyDate.format(thisYear === historyDate.year() ? HISTORY_DATE : HISTORY_DATE_YEAR);
};

export const mongoToTextYearComparison = date =>
  moment.parseZone(date).format(`${isDifferentYear(date) ? TRX_TEXT_YEAR : TRX_TEXT}`);

export function isDifferentYear(date) {
  const currentYear = moment().year();
  const comparisonYear = moment.parseZone(date).year();
  return currentYear !== comparisonYear;
}

export const getMinEndDate = startDate => {
  const startDateFormatted = startDate ? datepickerFormat(mongoToTrx(startDate)) : null;
  return startDateFormatted ? new Date(startDateFormatted) : null;
};

export function calculateDiffDays(startDate, endDate) {
  const date1 = moment(formatISO(startDate));
  const date2 = moment(formatISO(endDate));
  return date2.diff(date1, 'days');
}

export function isMissedTaskDate(date) {
  const today = moment();
  const taskDate = moment(date.replace('Z', ''));
  return taskDate.isBefore(today) && !taskDate.isSame(today, 'day');
}

export const plural = (value, singular, plural, zero) => {
  switch (value) {
    case 0:
      return zero || plural || singular + 's';
    case 1:
      return singular;
    default:
      return plural || singular + 's';
  }
};

plural.hour = value => `${value} ${plural(value, 'hr')}`;
plural.minute = value => `${value} ${plural(value, 'min')}`;
plural.day = value => `${value} ${plural(value, 'day')}`;
plural.week = value => `${value} ${plural(value, 'week')}`;
plural.month = value => `${value} ${plural(value, 'month')}`;

export const NOT_STARTED = 'not-started';
export const IN_PROGRESS = 'in-progress';
export const COMPLETED = 'completed';
export const NOT_READY_TO_START = 'not-ready-to-start';
export const TASK_STATUS = {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  NOT_READY_TO_START,
};
export const JOB_STATUS = {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
};

export const fixStatus = status => status.replace('-', ' ').toUpperCase();

export const mapBy = (collection, key) => collection.reduce((memo, item) => memo.set(item.get(key), item), Map());

export const calculateTimeLapse = (dateCreatedOn, formatFn = mongoToText) => {
  if (!dateCreatedOn) return 'less than 1min';
  const created = moment(dateCreatedOn);
  const now = moment();
  const diff = now.diff(created);
  if (diff < ONE_MINUTE) return 'Now';
  if (diff < ONE_HOUR) return plural.minute(now.diff(created, 'minutes')) + ' ago';
  if (diff < ONE_DAY) return plural.hour(now.diff(created, 'hours')) + ' ago';
  return formatFn(dateCreatedOn);
};

export const lastActivity = date => {
  const created = moment(date);
  const now = moment();
  const diff = now.diff(created);
  if (diff < ONE_MINUTE) return 'Now';
  if (diff < ONE_HOUR) return plural.minute(now.diff(created, 'minutes')) + ' ago';
  if (diff < ONE_DAY) return plural.hour(now.diff(created, 'hours')) + ' ago';
  if (now.diff(created, 'weeks') < 1) return plural.day(now.diff(created, 'days')) + ' ago';
  if (now.diff(created, 'months') < 1) return plural.week(now.diff(created, 'weeks')) + ' ago';
  if (now.diff(created, 'years') < 1) return plural.month(now.diff(created, 'months')) + ' ago';
  return created.format('MMMM D, YYYY');
};

export const isTaskOverdue = task => {
  const isOverdueFinish = task.get('overdue') === OVERDUE_FINISH ? 'overdue' : '';
  const isOverdueStart = task.get('overdue') === OVERDUE_START ? 'overdue' : '';
  return {
    isOverdueStart,
    isOverdueFinish,
  };
};

export const getTableMeasures = (width, totalCount) => {
  const colWidth = width * 0.2;
  const bigColWidth = width * 0.3;
  const smallColWidth = width * 0.1;
  const xsColWidth = width * 0.05;
  const rowHeight = 80;
  const maxTableHeight = window.innerHeight * (2 / 3);
  const tempTableHeight = rowHeight * totalCount + rowHeight;
  const tableHeight = tempTableHeight > maxTableHeight ? maxTableHeight : tempTableHeight;

  return {
    colWidth,
    bigColWidth,
    smallColWidth,
    xsColWidth,
    rowHeight,
    tableHeight,
  };
};

export const getAssigneeBadgeClassName = (assigneeId, accountId, userId, assigneeAccountId) => {
  if (!assigneeId) return 'assignee not-assigned';
  if (assigneeId === accountId) return 'assignee self-assigned'; //for builders
  if (assigneeId === userId) return 'assignee self-assigned';
  if (assigneeAccountId === accountId) return 'assignee account-assigned';
  return 'assignee sub-assigned not-badge pb-1 pt-1';
};

export const getUserRequestedBadgeClassName = (requestedByAccountId, userAccountID) => {
  const userStyle = 'text-dark assignee';
  const accountStyle = 'align-baseline d-inline  bg-transparent text-dark px-0 py-2';
  return requestedByAccountId === userAccountID ? userStyle : accountStyle;
};

export const getDeviceType = ({ navigator }) => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = navigator.appVersion.indexOf('Android') !== -1;
  return {
    isIOS,
    isAndroid,
  };
};

export const isMobileDevice = ({ navigator }) => {
  const device = getDeviceType({ navigator });
  return device.isIOS || device.isAndroid;
};

export function hasPermissionForAssignedTask(permissionKind, hasPermission, task, userId) {
  if (hasPermission(permissionKind)) return true;

  const getIsAssigned = userType => task.getIn([userType, '_id']) === userId;
  const isAssignedToMyself = getIsAssigned('userSuper') || getIsAssigned('userScheduler');
  const hasPermissionToEdit = isAssignedToMyself && hasPermission(permissionKind, 'assigned');

  return hasPermissionToEdit;
}

export const isUserInactive = (userId, accountUsers) => {
  if (!userId) return false;
  const foundUser = accountUsers?.find(user => (user.get('userId') || user.get('_id')) === userId);
  return foundUser?.get('status') === 'inactive';
};

export const readCookies = () => {
  return document.cookie.split('\n').reduce((obj, str) => {
    str.split(';').forEach(cookie => {
      const parts = cookie.split('=');
      if (parts[0] && parts[1]) obj[parts[0].replace(/\s+/g, '')] = parts[1].trim();
    });
    return obj;
  }, {});
};

export const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1);

export const setCheckedInStatus = task => {
  const status = task.get('status');
  const isCheckedIn = !!task.get('checkedIn');
  if (isCheckedIn) return task.set('status', 'checked-in').set('originalStatus', status);
  return task;
};

export const preventCharsInNumbers = e => {
  const allowedKeys = ['Backspace', 'Escape', 'Tab', 'ArrowLeft', 'ArrowRight'];
  if ((e.key < '0' || e.key > '9') && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const fnK = x => x;

export const defer = (fn = fnK, timeout = 100) => {
  setTimeout(fn, timeout);
};

export const mapActivitiesByDate = data => {
  const YESTERDAY = moment().subtract(1, 'days');
  const isToday = date => date.isSame(moment(), 'day');
  const isYesterday = date => date.isSame(YESTERDAY, 'day');

  const groups = data
    .map(activity => {
      if (activity.get('group') === 'loading') return activity;
      const date = moment(activity.get('createdOn'));
      if (isToday(date)) return activity.set('group', 'Today');
      if (isYesterday(date)) return activity.set('group', 'Yesterday');
      const group = date.format('MMMM D');
      return activity.set('group', group);
    })
    .groupBy(activity => activity.get('group'))
    .toArray();
  return fromJS(groups);
};

export const getUserDomain = user => {
  const domain = user.get('domain');
  const isBuilder = domain === 'builder';
  const isTrade = domain === 'sub';
  const isHomeowner = domain === 'homeowner';
  const isInstaller = domain === 'installer';
  return { isBuilder, isTrade, isHomeowner, isInstaller };
};

export const trimSpaces = (value, originalValue) => (typeof originalValue === 'string' ? value.trim() : null);

export const emptyStringToNull = (value, originalValue) =>
  typeof originalValue === 'string' && originalValue === '' ? null : trimSpaces(value, originalValue);

export const isURDateOverdue = (task, newDate) => {
  const today = moment().startOf('day');
  const status = task.get('status');
  const type = task.getIn(['changeRequest', 'type']);
  if (status === COMPLETED) return false;
  if (task.getIn(['job', 'status']) === NOT_STARTED) return false;
  if (type === 'new-start-date-request' && status === IN_PROGRESS) return false;
  if (type === 'new-expected-end-date' && status === NOT_STARTED) return false;

  const datePath = type == 'new-start-date-request' ? 'expectedStartDate' : 'expectedFinishDate';
  const targetDate = newDate || task.getIn(['changeRequest', datePath]);
  return moment(formatISO(targetDate))
    .startOf('day')
    .isBefore(today);
};

export const isDoubleUR = (task, fieldUR = 'changeRequest') => {
  if (!task) return false;
  const changeRequest = task.get(fieldUR);
  if (!changeRequest) return false;
  return changeRequest.get('type') === 'new-start-date-request' && changeRequest.get('newEndDateProposed');
};

export const preventConsecutiveSpaceChar = e => {
  const { value } = e.target;
  const isConsecutiveSpaceChar = value.charAt(value.length - 1) === ' ' && e.key === ' ';
  if (isConsecutiveSpaceChar) e.preventDefault();
};

export const orderDesc = (array, field) => {
  return array.sort((a, b) => {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  });
};

function updateDynamicColumnStyles(element, scrollValue, type = '') {
  if (scrollValue > 10) {
    element.style.left = `${scrollValue + (type === 'secondColumn' ? 0 : 30)}px`;
    if (type === 'header') {
      element.classList.add('headerColumn');
    } else if (type === 'secondColumn') {
      element.classList.add('secondColumn');
    }
  } else {
    element.classList.remove('headerColumn', 'secondColumn');
    element.style.left = '0px';
  }
}

export const handleTableScroll = throttle((event, scrollDivRef) => {
  let scrollValue;
  if (event && event.target) {
    scrollValue = event.target.scrollLeft;
  } else if (event && typeof event === 'object') {
    scrollValue = scrollDivRef?.current?.scrollLeft;
  }
  if (scrollValue !== undefined) {
    const thirdColumnCells = document.querySelectorAll('.trx-table .ReactVirtualized__Table__rowColumn:nth-child(3)');
    const secondColumnCells = document.querySelectorAll('.trx-table .ReactVirtualized__Table__rowColumn:nth-child(2)');
    const thirdColumnHeader = document.querySelector(
      '.trx-table .ReactVirtualized__Table__headerRow .ReactVirtualized__Table__headerColumn:nth-child(3)'
    );

    thirdColumnCells.forEach(cell => {
      updateDynamicColumnStyles(cell, scrollValue);
    });

    secondColumnCells.forEach(cell => {
      updateDynamicColumnStyles(cell, scrollValue, 'secondColumn');
    });

    if (thirdColumnHeader) {
      updateDynamicColumnStyles(thirdColumnHeader, scrollValue, 'header');
    }
  }
}, 10);

export const handleTableScrollStickyColumn2 = throttle((event, scrollDivRef) => {
  let scrollValue;
  if (event && event.target) {
    scrollValue = event.target.scrollLeft;
  } else if (event && typeof event === 'object') {
    scrollValue = scrollDivRef?.current?.scrollLeft;
  }
  if (scrollValue !== undefined) {
    const secondColumnCells = document.querySelectorAll('.trx-table .ReactVirtualized__Table__rowColumn:nth-child(2)');
    const secondColumnHeader = document.querySelector(
      '.trx-table .ReactVirtualized__Table__headerRow .ReactVirtualized__Table__headerColumn:nth-child(2)'
    );

    secondColumnCells.forEach(cell => {
      updateDynamicColumnStyles(cell, scrollValue, 'secondColumn');
    });

    if (secondColumnHeader) {
      updateDynamicColumnStyles(secondColumnHeader, scrollValue, 'header');
    }
  }
}, 10);

export const scrollbarStyles = css`
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.gray200};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.white};
  }
`;

export function callOnlyTimes(times) {
  let count = 0;
  return fn => {
    if (++count <= times) fn();
  };
}
