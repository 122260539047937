import React from 'react';
import { MultipleAssigneeSelect } from '../AssigneeSelect';
import styled from 'styled-components';

export const CommunityHeader = ({
  className,
  community,
  // users,
  // loggedUserId,
  children,
  domain,
  linkToOuttake,
  linkToIntake,
  controller,
}) => {
  // const communityId = community.get('_id');
  const isSubDomain = domain === 'subs';
  // TODO: pending to have all community info from BE...
  return (
    <CommunityHeaderContainer className={className}>
      <div className="d-flex flex-row position-relative">
        <span className="h4 mb-0 font-weight-bold  text-truncate " style={{ maxWidth: '90%' }}>
          {community.get('name')}
        </span>
        {/* <ChipStatus {...STATUS_MAP[community.get('status')]} className="mx-2" /> */}
        {/* <OnTime onTime={community.get('onTime')} /> */}
      </div>
      <small>{community.get('address') || ''}</small>
      {/* <div className="d-flex flex-row mt-3">
        <TargetDate title="Target Finish" date={community.get('targetFinish')} />
        <TargetDate title="Expected Finish" date={community.get('expFinish')} />
        <UsersTags className="mx-5" title="Scheduler Assigned" names={community.get('schedulers').toArray()} />
        <UsersTags className="mx-5" title="Super" names={community.get('supers').toArray()} />
      </div> */}
      <div className="d-flex flex-wrap align-items-start my-3">
        <MultipleAssigneeSelect
          title={`${isSubDomain ? 'Trade ' : ''}Area Manager`}
          controller={controller}
          currentList={community.get('areaManagers').toJS()}
          // currentUser={areaManager}
          // users={users}
          // onChange={user => setAreaManager(user, communityId)}
          // placeholder="Choose Area Manager"
          // loggedUserId={loggedUserId}
          // displayInitials
        />
        {children}
      </div>
    </CommunityHeaderContainer>
  );
};
export default CommunityHeader;

const CommunityHeaderContainer = styled.div`
  width: 62%;
`;
