import React from 'react';
import { fromJS } from 'immutable';
import { isEqual } from 'lodash';
import { markAsSync, useController } from '../../useController';
import { useAppContext } from '../../globalAppContext';

export const LIST_VIEW = 'LIST_VIEW';
export const EDIT_VIEW = 'EDIT_VIEW';

const emptyState = fromJS({
  view: EDIT_VIEW,
  selected: [],
  isSaveDisabled: true,
  hideBackButton: true,
});

const actions = { setListView, setEditView, addAreaManager, toggleDisableButton };

const initState = (initView, community, appState) => state => {
  const hideBackButton = initView === EDIT_VIEW;
  const areaManagers = community
    .get('areaManagers')
    .map(formatOptions)
    .toArray();
  const usersField = appState.getIn(['account', 'type']) === 'builder' ? 'usersActive' : 'regularUsersActive';
  const options = appState
    .getIn(['account', usersField])
    .map(formatOptions)
    .toArray();
  const selected = options.filter(option => areaManagers.some(manager => manager.get('_id') === option.get('_id')));
  const managerIds = areaManagers.map(item => item.get('_id')).sort();
  return state
    .set('view', initView)
    .set('selected', selected)
    .set('managerIds', managerIds)
    .set('options', options)
    .set('hideBackButton', hideBackButton);
};

export function MultipleAssigneeContext(community, { initView, ...props }) {
  const { appState } = useAppContext();
  const [state, controller] = useController(
    actions,
    emptyState,
    { community, ...props },
    initState(initView, community, appState)
  );
  const selected = state.get('selected');
  const managerIds = state.get('managerIds');

  React.useEffect(() => {
    const selectedIds = selected.map(item => item.get('_id')).sort();
    const isListUpdated = !isEqual(selectedIds, managerIds);
    controller.toggleDisableButton(isListUpdated);
  }, [controller, selected, managerIds]);

  return { state, controller };
}

const formatOptions = item => {
  return fromJS({
    _id: item.get('_id'),
    name: item.get('name'),
  });
};

markAsSync(setEditView);
function setEditView(state) {
  return state.set('view', EDIT_VIEW);
}

markAsSync(setListView);
function setListView(state) {
  const selected = this.community.get('areaManagers').toArray();
  return state.set('view', LIST_VIEW).set('selected', selected);
}

markAsSync(addAreaManager);
function addAreaManager(state, assignees) {
  return state.set('selected', assignees);
}

markAsSync(toggleDisableButton);
function toggleDisableButton(state, isListUpdated) {
  return state.set('isSaveDisabled', !isListUpdated);
}
