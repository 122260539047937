import React from 'react';
import { HeaderUser } from './index';
import { UserNameLabel, UsersTags } from '../Tags';
import { LIST_VIEW, EDIT_VIEW } from '../Community/MultipleAssigneeModal/MultipleAssignee.context';

export function MultipleAssigneeSelect({ title, controller, ...rest }) {
  const { hasPermission = true, currentList = [] } = rest;
  const isEmpty = !currentList.length;

  const openMultipleAssigneeModal = initView => {
    if (hasPermission) controller.openMultipleAssigneeModal(initView);
  };

  return (
    <HeaderUser className="d-flex mb-3 flex-column mr-4 w-12rem" disabled={!hasPermission}>
      {isEmpty ? (
        <>
          {title && <small className="text-muted">{title}</small>}
          <div className="d-flex flex-row align-items-center" onClick={() => openMultipleAssigneeModal(EDIT_VIEW)}>
            <UserNameLabel className="text-truncate not-assigned hover-active" hasPermission={hasPermission}>
              Needs Assignment
            </UserNameLabel>
          </div>
        </>
      ) : (
        <UsersTags
          className="mr-4 w-14rem"
          maxTags={2}
          title={title}
          users={currentList.slice(0, 3)}
          onShowMore={e => {
            e.preventDefault();
            e.stopPropagation();
            openMultipleAssigneeModal(LIST_VIEW);
          }}
          isClickEnabled
        />
      )}
    </HeaderUser>
  );
}
