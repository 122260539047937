import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Initials, UserNameLabel } from '../Tags';
import { useFade } from './useFade';
import { useTimeAgo } from '../useTimeAgo';
import { mongoToHistory, getUserRequestedBadgeClassName } from '../utils';
import { useAppContext } from '../globalAppContext';

export const Notification = ({
  name,
  linkTo,
  children,
  time,
  markAsSeen,
  redDot,
  userInitials,
  requestedBy,
  isInactive = false,
}) => {
  const [className, onClick] = useFade(markAsSeen);
  const appContext = useAppContext();
  const { user } = appContext.appState.toObject();

  const userClassName = getUserRequestedBadgeClassName(requestedBy?.get('accountId'), user.get('accountId'));

  return (
    <li className={className}>
      <i className={cn({ 'red-dot': redDot })} />
      <div className="d-flex flex-row">
        <Initials name={userInitials || name} size={32} />
        <p className="mt-1 mb-2 text-secondary">
          <Link
            onClick={onClick}
            to={linkTo}
            className="stretched-link link-name text-truncate text-decoration-none text-dark notification-redirect"
          >
            <UserNameLabel isInactive={isInactive} className={userClassName}>
              {name}
            </UserNameLabel>
          </Link>{' '}
          {children}
        </p>
      </div>
      <span className="trx-time">{useTimeAgo(time, mongoToHistory)}</span>
    </li>
  );
};
